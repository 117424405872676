<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol col="auto">
        <CDropdown
          togglerText="MODIFICA"
          color="primary"
          :disabled="!canView(PERMS.ORDERS_EDIT)"
        >
          
          <CDropdownItem @click="setDelivered()" v-if="producer.ddmp.delivery_type!=3"
            >Segna come ritirato</CDropdownItem
          >
          <CDropdownItem @click="setNotDelivered()" v-if="producer.ddmp.delivery_type!=3"
            >Segna come NON ritirato</CDropdownItem
          >
          <CDropdownItem @click="createIncident(INCIDENT_TYPE.INCIDENT)">Apri segnalazione</CDropdownItem>
          <CDropdownItem @click="createIncident(INCIDENT_TYPE.CANCELLATION)">Annulla</CDropdownItem>
        </CDropdown>
      </CCol>
      <CCol col="auto">
        <OrdersPrints
          :prints="producer.prints"
          title="STAMPA"
          :types="getProducerPrints"
        />
      </CCol>
    </CRow>

    <div class="d-flex justify-content-center m-3" v-if="loading">
      <CSpinner color="info" />
    </div>

    <ejs-grid
      ref="grid"
      v-show="!loading"
      :dataSource="rows"
      :allowSelection="true"
      :selectionSettings="selectionSettings"
      :detailTemplate="detailTemplate"
      :dataBound="onDataBound"
      @rowSelected="rowSelectedHandler($event)"
      @rowDeselected="rowDeselectedHandler($event)"
      @detailDataBound="onDetailDataBound"
    >
      <e-columns>
        <e-column type="checkbox" width="40" textAlign="left"></e-column>
        <e-column
          isPrimaryKey="{true}"
          field="id"
          headerText="Cassa"
          width="80"
          :template="accountTemplate"
        ></e-column>
        <e-column
          field="order.order.id"
          headerText="Nome"
          :template="nameTemplate"
          width="200"
        ></e-column>
        <e-column
          field="info"
          headerText="Info"
          :template="infoTemplate"
        ></e-column>
        <e-column
          field="price"
          textAlign="right"
          headerText="Prezzo"
          :template="priceTemplate"
          width="100"
        ></e-column>
        <e-column
          field="action"
          headerText=""
          width="45"
          :template="actionsTemplate"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import { DetailRow } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import OrdersTableAccount from "./OrdersTableAccount";
import OrdersTableName from "./OrdersTableName";
import OrdersTableInfo from "./OrdersTableInfo";
import OrdersTablePrice from "./OrdersTablePrice";
import OrdersTableActions from "./OrdersTableActions";
import OrdersTableRowDetails from "./OrdersTableRowDetails";
import EventBus from "../../../../../../helpers/EventBus";
import { buildPredicate } from "../../../../../../helpers/common";
import orderManagementMixin from "../../../share/mixin";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import OrdersPrints from "../../../share/OrdersPrints";
import { INCIDENT_TYPE } from "../../../../../../config/global";

export default {
  name: "OrdersTable",

  components: {
    OrdersPrints,
  },

  mixins: [orderManagementMixin],

  props: {
    producer: { type: Object, required: true },
  },

  provide: {
    grid: [DetailRow],
  },

  watch: {
    producer() {
      this.itemSelected = Object.assign({});
      this.loadData();
    },
  },

  data: function () {
    const roleId = this.$store.state.role.id;
    const roundId = this.$store.state.rounds.round.id;
    const endpoint = filtersMap("ddmp.panel2.list.endpoint");
    const dm = GetDataManagerNew(endpoint, [roleId, roundId]);

    return {
      INCIDENT_TYPE,
      PERMS,
      dm: dm,
      loading: false,
      rows: [],
      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      itemSelected: {},

      accountTemplate: () => {
        return { template: OrdersTableAccount };
      },

      nameTemplate: () => {
        return { template: OrdersTableName };
      },

      infoTemplate: () => {
        return { template: OrdersTableInfo };
      },

      priceTemplate: () => {
        return { template: OrdersTablePrice };
      },

      actionsTemplate: () => {
        return { template: OrdersTableActions };
      },

      detailTemplate: () => {
        return {
          template: {
            extends: OrdersTableRowDetails,
            propsData: {
              grid: this.$refs.grid,
              producer: this.producer,
            },
          },
        };
      },

      weightEntryData: null,
      weightModalShow: false,
    };
  },

  computed: {
    lang: function () {    
      return this.$i18n.locale;
    },
    getProducerPrints() {
      var res = ['producer_orders']
      // , 'producer_orders_direct'
      if (this.producer.ddmp.delivery_type==3){
        res.push('producer_orders_direct')
      }
      return res
    }
  },

  mounted() {
    EventBus.$on("orders:detail", this.onDetailsSelected);
    EventBus.$on("orders:priceUpdate", this.onDetailPriceUpdate);
    this.loadData();
  },

  beforeDestroy() {
    EventBus.$off("orders:detail", this.onDetailsSelected);
    EventBus.$off("orders:priceUpdate", this.onDetailPriceUpdate);
  },

  methods: {
    loadData() {
      const query = this.prepareQuery();
      this.loading = true;

      this.dm
        .executeQuery(query)
        .then((response) => {
          this.loading = false;
          this.rows = response.result;
        })
        .catch((response) => {
          this.loading = false;
          console.error(response);
        });
    },

    prepareQuery() {
      const { keyword } = this.$store.state.rounds.filters;
      let predicate = new Predicate("ddmp", "equal", this.producer.ddmp.id);

      const map = filtersMap("ddmp.panel2.list.filters");
      predicate = buildPredicate(
        predicate,
        map,
        this.$store.state.rounds.filters
      );

      let query = new Query().where(predicate);

      const searchFields = filtersMap("ddmp.panel2.list.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getSelectedParentKey() {
      return "porders";
    },

    getSelectedChildrenKey() {
      return "details";
    },


    createIncident(type) {
      const selected = this.getSelected();
      if (selected) {
        const entities = [];
        Object.keys(selected).forEach((type) => {
          entities.push({ type, items: [...selected[type]] });
        });

        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          type: type,
          entities,
          show: true,
        });
      }
    },

    setDelivered() {
      const selected = this.getSelected();
      if (selected) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.next"),
            ...this.mapSelectedToIds(selected),
          })
          .then(this.onUpdateStatusSuccess);
      }
    },

    setNotDelivered() {
      const selected = this.getSelected();
      if (selected) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.prev"),
            ...this.mapSelectedToIds(selected),
          })
          .then(this.onUpdateStatusSuccess);
      }
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
